export type Color = string | undefined;

export class Colors {
    static readonly White: Color = '#F0F0F0';
    static readonly DarkGray: Color = '#19202B';
    static readonly Gray: Color = '#222D3D';
    static readonly LightGray: Color = '#374964';
    static readonly VeryLightGray: Color = '#465A7A';
    static readonly ExtraLightGray: Color = `#909DB0`;
    static readonly Red: Color = 'rgba(205, 81, 73, 0.39)';
    static readonly BrightRed: Color = '#CD5149';
    static readonly BrightGreen: Color = '#59DDA6';
    static readonly Green: Color = '#23B276';
}