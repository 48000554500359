import * as React from "react";
import styled from "styled-components";
import {Colors} from "../../common/colors/Colors";
import SlideX from "../../common/effects/SlideX";
import {Footer} from "./Footer";
import {Link} from "../../common/button/Link";


export function Icons8Attributions() {
    return <Footer>
        <SlideX xPixelOffset={-30}>
            <Link target="_blank" href="https://icons8.com/icons/set/bill-cipher">Bill Cipher</Link>, <Link
            target="_blank" href="https://icons8.com/icons/set/amethyst-universe">Amethyst Universe</Link> and other
            icons by <Link target="_blank" href="https://icons8.com">Icons8</Link>
        </SlideX>
    </Footer>
}