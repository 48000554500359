import * as React from 'react';
import {StarRatings} from "./rating/StarRatings";
import styled from "styled-components";

export interface ScoreProps {
    score: number, // [0,1]
}

export class Score extends React.Component<ScoreProps> {

    render(): React.ReactNode {
        return (
            <Container>
                <StarRatings amount={this.props.score * 100}/>
            </Container>
        )
    }
}

const Container = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;
