import React from 'react';
import {Annotation as AnnotationData, Answer} from "../../model/Answer";
import {BackgroundBlink} from "../../../common/effects/BackgroundBlink";
import {StrikeThrough} from "../../../common/effects/StrikeThrough";
import {Text} from "../../../common/text/Text";
import SlideUpIn from "../../../common/effects/SlideUpIn";
import styled, {keyframes} from "styled-components";
import {Color, Colors} from "../../../common/colors/Colors";
import {Underlined} from "../../../common/effects/Underlined";
import {UpArrowIcon} from "../../../common/icons/Icons";
import {SpellingEdit} from "../annotate/SpellingEdit";
import {Row} from "../../../common/blocks/Blocks";
import {Bounce} from "../../../common/effects/Bounce";
import FallIn from "../../../common/effects/FallIn";

export type Props = {
    answer: string
}

export function PendingAnswer(props: Props) {
    return <Text color={Colors.ExtraLightGray}>{props.answer}</Text>;
}
