import axios, {AxiosResponse} from "axios";
import {Question} from "../model/Question";
import {Answer} from "../model/Answer";

export type StartSessionRequest = {
    subjectId: string,
}

export type StartSessionResponse = {
    sessionId: string
}

export type CompleteSessionRequest = {
    sessionId: string,
}

export type NextSessionRequest = {
    sessionId: string,
}

export type StartSessionFromTemplateResponse = {
    sessionId: string
}

export type NextQuestionRequest = {
    sessionId: string,
}

export type NextQuestionResponse = {
    question: Question,
}

export type SubmitAnswerRequest = {
    sessionId: string,
    questionId: string,
    value: string,
}

export type SubmitAnswerResponse = {
    answer: Answer,
}

export type GetResultsRequest = {
    sessionId: string,
}

export type GetResultsResponse = {
    answers: Array<Answer>,
}

export interface SessionService {
    start(request: StartSessionRequest): Promise<StartSessionResponse>,

    complete(request: CompleteSessionRequest): Promise<void>,

    nextSession(request: NextSessionRequest): Promise<StartSessionResponse>,

    nextQuestion(request: NextQuestionRequest): Promise<NextQuestionResponse>,

    submitAnswer(request: SubmitAnswerRequest): Promise<SubmitAnswerResponse>,

    getResults(request: GetResultsRequest): Promise<GetResultsResponse>,
}

export class RESTSessionService implements SessionService {

    async start(request: StartSessionRequest): Promise<StartSessionResponse> {
        let response = await axios.post<StartSessionRequest, AxiosResponse<StartSessionResponse>>(
            '/play/sessions',
            request,
        );
        return response.data;
    }


    async complete(request: CompleteSessionRequest): Promise<void> {
        let response = await axios.post<StartSessionRequest, AxiosResponse<void>>(
            `/play/sessions/${request.sessionId}/complete`,
        );
        return response.data;
    }

    async nextSession(request: NextSessionRequest): Promise<StartSessionResponse> {
        let response = await axios.post<StartSessionRequest, AxiosResponse<StartSessionResponse>>(
            '/play/sessions/next',
        );
        return response.data;
    }

    async nextQuestion(request: NextQuestionRequest): Promise<NextQuestionResponse> {
        let response = await axios.get<NextQuestionRequest, AxiosResponse<Question>>(
            `/play/sessions/${request.sessionId}/question`,
        );
        return {
            question: response.data
        };
    }

    async submitAnswer(request: SubmitAnswerRequest): Promise<SubmitAnswerResponse> {
        let response = await axios.post<SubmitAnswerRequest, AxiosResponse<Answer>>(
            `/play/sessions/${request.sessionId}/answer`,
            request,
        );
        return {
            answer: response.data
        };
    }
    async getResults(request: GetResultsRequest): Promise<GetResultsResponse> {
        let response = await axios.get<SubmitAnswerRequest, AxiosResponse<Array<Answer>>>(
            `/play/sessions/${request.sessionId}/results`,
        );
        return {
            answers: response.data
        };
    }

}