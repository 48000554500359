import * as React from "react";
import styled from "styled-components";
import {Colors} from "../../common/colors/Colors";

export function Footer(props: {children: React.ReactNode}) {
    return <Container>
        <Child>{props.children}</Child>
    </Container>
}

export const Container = styled.div`
  background-color: transparent;
  position: absolute;
  display: flex;
  flex-direction: column;
  justify-content: center;
  bottom: 0;
  //left:0;
  //right:0;
  width: 100%;
  //height: 50px;
  font-size: 12px;
  color: ${Colors.LightGray};
  padding: 10px;
`;

export const Child = styled.div`
  position: relative;
  display: flex;
`;

