import * as React from "react";
import styled from "styled-components";
import {SessionService} from "../service/SessionService";
import {Question} from "../model/Question";
import {Answer} from "../model/Answer";
import {ProgressBar} from "../components/progressbar/ProgressBar";
import {Activity, Props as ActivityProps} from "../components/activity/Activity";
import {LoadingIndicator} from "../../common/loader/LoadingIndicator";
import {Button} from "../../common/button/Button";
import {Avatar} from "../../common/avatar/Avatar";
import {MainView, SceneContainer} from "../components/SceneContainer";
import {SlideY} from "../../common/effects/SlideY";
import {useParams, useHistory} from "react-router-dom";
import {Icons8Attributions} from "../components/Icons8Attributions";


export interface Props {
    sessionService: SessionService,
    sessionId: string,
    onComplete: (sessionId: string) => void,
}

export interface State {
    loading: boolean,
    answerSubmitted: boolean,
    progress: number,
    goal: number,
    completed: boolean,
    activity: ActivityProps | null,
    // these two should find a better place to stay...
    questionAvatarId: string,
    userAvatarId: string,
    answers: Array<Answer>,
}

class SessionScene extends React.Component<Props, State> {

    constructor(props: Readonly<Props>) {
        super(props);
        let avatars = Avatar.getRandomIds(2);
        this.state = {
            loading: true,
            answerSubmitted: false,
            progress: 0,
            goal: 3, // default three correct answers in session
            completed: false,
            questionAvatarId: avatars[0],
            userAvatarId: avatars[1],
            activity: null,
            answers: [],
        }
    }

    componentDidMount() {
        this.fetchNextQuestion();
        this.fetchResults();
    }

    render(): React.ReactNode {
        return <SceneContainer>
            <ActivityContainer>
                {this.renderActivity()}
            </ActivityContainer>
            <ContinueButtonSection>
                {this.renderContinueButton()}
            </ContinueButtonSection>
            <ProgressSection>
                <SlideY>
                    {this.renderProgress()}
                </SlideY>
            </ProgressSection>
            <Icons8Attributions/>
        </SceneContainer>
    }

    renderActivity() {
        if (this.state.activity === null) {
            return <LoadingIndicator/>;
        }
        let activity = this.state.activity as ActivityProps;
        return <Activity key={activity.index}
                         {...activity}/>;
    }

    fetchNextQuestion() {
        this.setState({loading: true});
        this.props.sessionService.nextQuestion({sessionId: this.props.sessionId})
            .then(response => this.handleNextActivity(response.question));
    }

    fetchResults() {
        this.props.sessionService.getResults({sessionId: this.props.sessionId})
            .then(res => this.setState({answers: res.answers}));
    }

    handleNextActivity(question: Question) {
        let prevIndex = this.state.activity?.index ? this.state.activity.index : 0;
        let activityProps: ActivityProps = {
            index: prevIndex + 1,
            question: question,
            onSubmit: answer => this.submitAnswer(answer.value),
            submitted: false,
            submitting: false,
            questionAvatarId: this.state.questionAvatarId,
            userAvatarId: this.state.userAvatarId,
        };
        this.setState({
            loading: false,
            answerSubmitted: false,
            activity: activityProps,
        });
    }

    submitAnswer(value: string) {
        let updatedActivity = {...this.state.activity} as ActivityProps;
        updatedActivity.submitting = true;
        this.setState({activity: updatedActivity});
        this.props.sessionService.submitAnswer({
            sessionId: this.props.sessionId,
            questionId: updatedActivity.question.id,
            value: value
        })
            .then(response => this.onAnswerSubmitted(response.answer));
    }

    onAnswerSubmitted(answer: Answer) {
        let updatedActivity = {...this.state.activity} as ActivityProps;
        updatedActivity.submitting = false;
        updatedActivity.submitted = true;
        updatedActivity.answer = answer;

        let history = [...this.state.answers];
        history.push(answer);
        let newProgress = this.calcProgress(history);
        let isSessionComplete = newProgress >= 1;
        this.setState({
            activity: updatedActivity,
            progress: newProgress,
            completed: isSessionComplete,
            answers: history,
        });
    }

    calcProgress(answers: Array<Answer>) {
        let scoreSum = answers
            .map(value => value.score)
            .reduce((prev, curr) => prev + curr, 0);
        return scoreSum / this.state.goal;
    }

    renderContinueButton() {
        let activity = this.state.activity as ActivityProps;
        if (this.state.loading || !activity.submitted) {
            return null;
        }
        if (this.state.completed) {
            return <ContinueButton>
                <SlideY>
                    <Button onClick={() => this.handleSessionCompleted()}>{"View Results"}</Button>
                </SlideY>
            </ContinueButton>;
        }
        return <ContinueButton>
            <SlideY>
                <Button onClick={() => this.handleNextQuestion()}>{"Continue"}</Button>
            </SlideY>
        </ContinueButton>;
    }

    renderProgress() {
        return <ProgressBar value={this.calcProgress(this.state.answers)}/>;
    }

    handleSessionCompleted() {
        const sessionId = this.props.sessionId;
        this.props.sessionService
            .complete({sessionId: sessionId})
            .then(() => this.props.onComplete(sessionId));
    }

    handleNextQuestion() {
        return this.fetchNextQuestion();
    }
}

export function PlaySessionPage(props: Omit<Props, 'sessionId' | 'onComplete'>) {
    let {sessionId} = useParams();
    let history = useHistory();
    return <SessionScene {...props} sessionId={sessionId}
                         onComplete={sessionId1 => {
                             history.replace(`/sessions/${sessionId}/results`)
                         }}/>
}

const ActivityContainer = styled(MainView)`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
`;

const ProgressSection = styled.div`
    grid-column: 2;
    grid-row: 3;
    justify-self: center;
    align-self: center;
    
    width: 400px;
`;

const ContinueButtonSection = styled.div`
  grid-column: 2 / 3;
  grid-row: 3 / 3;
  height: 80px;
  width: 100%;
  position: absolute;
  display: flex;
  flex-direction: row;
  justify-content: center;
`;

const ContinueButton = styled.div`
//grid-column: 2;
//    grid-row: 3;
  margin-left: auto;
  margin-right: auto;
  //position: absolute;
  //bottom: 100px;
  z-index: 1000;
  
  //text-align: center;
  //justify-self: center;
  //  align-self: center;
`;