import styled from "styled-components";
import {Button} from "../../../common/button/Button";

export const Form = styled.form`
    grid-column: 2 / 3;
    grid-row: 2 / 3;
    align-self: center;
    justify-self: center;
`;

export const Row = styled.div`
    margin-top: 10px;
    margin-bottom: 10px;
    width: 250px;
`;

export const ButtonRow = styled(Row)`
   margin-top: 30px;
   display: flex;
   justify-content: center;
`;

export const Title = styled(Row)`
    font-weight: 600;
    text-align: center;
    margin-bottom: 30px;
    font-size: 24px;
`;

export const DemoLink = styled(Row)`
    font-weight: 600;
    text-align: center;
    margin-bottom: 30px;
    font-size: 24px;
`;

export const SubTitle = styled(Row)`
    font-weight: 600;
    text-align: center;
    margin-bottom: 30px;
    font-size: 14px;
`;

export const EmailLabel = styled(Row)`
    font-weight: 600;
`;

export const PasswordLabel = styled(Row)`
    font-weight: 600;
`;

export const Input = styled.input`
    font-weight: 600;
    width:100%;
    padding: 4px;
    border-radius: 2px;
    border: none;
    margin-bottom: 20px;
`;

export const LinkButton = styled(Button)`
  background-color: transparent;
  border: none;
  font-weight: 400;
`;

