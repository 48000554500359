import * as React from 'react';
import {Avatar} from "../../../common/avatar/Avatar";
import styled from "styled-components";
import {LoadingIndicator} from "../../../common/loader/LoadingIndicator";
import {SlideY} from "../../../common/effects/SlideY";
import {Question} from "../../model/Question";
import {TextAnswerInput} from "../answer/TextAnswerInput";
import {Annotation, Answer} from "../../model/Answer";
import {BounceIn} from "../../../common/effects/BounceIn";
import SlideUpIn from "../../../common/effects/SlideUpIn";
import Delay3 from "../../../common/effects/Delay3";
import {CorrectiveFeedback} from "./CorrectiveFeedback";
import {Result} from "./Result";
import {PendingAnswer} from "../answer/PendingAnswer";
import {AnnotatedAnswer} from "../answer/AnnotatedAnswer";
import {Bounce} from "../../../common/effects/Bounce";

type SubmitAnswerEvent = {
    value: string,
}

export type Props = {
    index: number,
    question: Question,
    onSubmit: (answer: SubmitAnswerEvent) => void,
    answer?: Answer,
    submitting: boolean,
    submitted: boolean,
    questionAvatarId: string,
    userAvatarId: string,
}

type State = {
    value: string,
    selectedAnnotation?: Annotation,
    showMistakes: boolean,
}

export class Activity extends React.Component<Props, State> {

    constructor(props: Readonly<Props>) {
        super(props);
        this.state = {
            value: "",
            showMistakes: false,
        }
    }

    render(): React.ReactNode {
        return <Container>
            <ChallengeTitle>
                <SlideY>{this.renderTitle()}</SlideY>
            </ChallengeTitle>
            <AuthorRow>
                <Delay3 millis={1000}>
                    <ActorIcon>
                        <SlideY>
                            <Avatar id={this.props.questionAvatarId}/>
                        </SlideY>
                    </ActorIcon>
                    <ActorText>
                        <SlideY>
                            {this.renderAuthorText()}
                        </SlideY>
                    </ActorText>
                </Delay3>
            </AuthorRow>
            <UserRow>
                <Delay3 millis={1500}>
                    <ActorIcon>
                        {this.renderUserAvatar()}
                    </ActorIcon>
                    <ActorText>
                        {this.renderUserText()}
                    </ActorText>
                </Delay3>
            </UserRow>
            <AuthorRow>
                {this.renderResultRow()}
            </AuthorRow>
        </Container>
    }

    private renderTitle() {
        return this.props.question.title;
    }

    private renderUserAvatar() {
        return (
            <SlideY>
                <Avatar id={this.props.userAvatarId}/>
            </SlideY>
        )
    }

    private renderUserText() {

        let content;

        if (this.props.submitting) {
            return <PendingAnswer answer={this.state.value}/>;
        } else if(this.props.submitted) {
            let {answer} = this.props;
            content = <AnnotatedAnswer alignment={answer!.annotations}
                                       answer={answer!}
                                       onEditSelected={(edit) => this.handleEditSelected(edit)}/>;

        } else {
            content = <TextAnswerInput onChange={answer => this.handleAnswerChange(answer)}
                                       onSubmit={answer => this.handleSubmit(answer)}/>;
        }
        return content;
    }

    private handleEditSelected(edit: Annotation) {
        this.setState({selectedAnnotation: edit, showMistakes: true});
    }

    private renderAuthorText() {
        return this.props.question.item;
    }

    private renderResultRow() {
        let actorText = null;

        if (this.props.submitting) {
            actorText = <LoadingIndicator/>;
        }
        if (this.props.submitted) {
            let score = this.props.answer!.score;
            let mistakes = this.props.answer!.annotations
                .filter(value => value.operation !== 'equal')
                .filter(value => !value.ignored)
                .length;
            actorText = <Result score={score} mistakes={mistakes}
                                annotation={this.state.selectedAnnotation}
                                showFeedback={this.state.showMistakes}/>;
        }

        if (actorText != null) {
            return <React.Fragment>
                <ActorIcon>
                    <SlideUpIn>
                        <Avatar id={this.props.questionAvatarId}/>
                    </SlideUpIn>
                </ActorIcon>
                <ActorText>
                    <SlideUpIn>
                        {/*{this.renderCorrectiveFeedback()}*/}
                        {actorText}
                    </SlideUpIn>
                </ActorText>
            </React.Fragment>;
        }

        return null;
    }

    private toggleShowMistakes(e: any) {
        e.preventDefault();
        let showMistakes = this.state.showMistakes;
        this.setState({showMistakes: !showMistakes});
    }

    private handleAnswerChange(value: string) {
        this.setState({value: value});
    }

    private handleSubmit(value: string) {
        this.props.onSubmit({value: value});
    }
}

const Container = styled.div`
  //width: 500px;
  //height: 400px;
`;
const ChallengeTitle = styled.div`
    font-size: 18px;
    font-weight: bold;
    grid-row: 1;
    height: 80px;
    width: 500px;
`;
const ActorRow = styled.div`
    display: grid;
    grid-template-columns: 50px auto;
    grid-template-rows: 100px;
    column-gap: 20px;
    height: 100px;
    width: 500px;
`;
const ActorIcon = styled.div`
    align-self: center;
    justify-self: center;
    display: flex;
    align-items: center;
    justify-content: center;
`;
const ActorText = styled.div`
    font-size: 18px;
    font-weight: bold;
    grid-column: 2;
    grid-row: 1;
    align-self: end;
    //overflow: hidden;
    display: flex;
    flex-direction: row;
    align-items: center;
    height: 100%;
`;
const AuthorRow = styled(ActorRow)`
    grid-row: 2;
`;
const UserRow = styled(ActorRow)`
    grid-row: 3;
`;