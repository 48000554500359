import React, {Component} from 'react';
import styled, {keyframes} from "styled-components";

interface Props {
    millis: number,
}

interface State {
    visible: boolean,
}

export default class Delay2 extends Component<Props, State> {

    static defaultProps = {
        millis: 100
    };

    constructor(props: Readonly<Props>) {
        super(props);
        this.state = {
            visible: false
        }
    }

    componentDidMount(): void {
        setTimeout(() => {
            this.setState({visible: true})
        }, this.props.millis);
    }

    render() {
        let key = this.state.visible ? 2 : 1;
        return <Styled key={key} visible={this.state.visible}>{this.props.children}</Styled>;
    }
}

const Styled = styled.div<State>`
    // visibility: ${props => props.visible ? "visible" : "hidden"};
    opacity: ${props => props.visible ? 1 : 0} !important;
`;