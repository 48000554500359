import axios, {AxiosResponse} from "axios";
import {User} from "../model/User";

export interface LoginRequest {
    email: string,
    password: string,
}

export interface RegistrationRequest extends LoginRequest {
}

export interface UserService {

    me(): Promise<User | null>;

    isAuthenticated(): Promise<boolean>;

    login(request: LoginRequest): Promise<User>;

    register(request: RegistrationRequest): Promise<User>;

}

export class RESTUserService implements UserService {

    async me(): Promise<User | null> {
        let user = null;
        try {
            let response = await axios.get<void, AxiosResponse<User>>(
                '/users/me',
            );
            user = response.data;
        } catch (e) {
            console.log("error: " + e);
        }
        return user;
    }

    async isAuthenticated(): Promise<boolean> {
        const token = localStorage.getItem('jwtToken');
        if (token === null) {
            return false;
        }
        let user = await this.me();
        return !!user;
    }

    async login(request: LoginRequest): Promise<User> {
        let response = await axios.post<LoginRequest, AxiosResponse<User>>(
            '/login',
            request
        );
        if (response.status === 200) {
            const jwt = response.headers['authorization'];
            localStorage.setItem("jwtToken", jwt);
        }
        return {
            username: response.data.username
        }
    }

    async register(request: RegistrationRequest): Promise<User> {
        let response = await axios.post<RegistrationRequest, AxiosResponse<User>>(
            '/users/signup',
            request,
            {
                transformRequest: [(data, headers) => {
                    delete headers.common['authorization'];
                    headers['Content-Type'] = 'application/json';
                    return JSON.stringify(data);
                }]
            }
        );
        return await this.login(request);
    }
}