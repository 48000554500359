import React, {Component} from "react";
import styled from "styled-components";
import {Color} from "../colors/Colors";
import {Row} from "../blocks/Blocks";

type Props = {
    color: Color,
}

export class Text extends Component<Props> {

    static defaultProps = {
        color: '#f0f0f0'
    }

    render() {
        return <Styled {...this.props}/>;
    }
}

export const Styled = styled(Row)<Props>`
  display: inline-block;
  color: ${props => props.color};
  transition: color 200ms ease-out;
`;