import React, {Component} from 'react';
import styled, {keyframes} from "styled-components";
import Appear from "./Appear";

export type Props = {
    yPixelOffset: number,
}

export class SlideY extends Component<Props> {

    static defaultProps = {
        yPixelOffset: 10,
    }

    render() {
        return <Styled {...this.props}><Appear>{this.props.children}</Appear></Styled>
    }
}

const slideYKeyframes = (y: number) => keyframes`
    0% {
        transform: translateY(${y}px);
    }
    100% {
        transform: translateY(0px);
    }
`;

const Styled = styled.div<Props>`
    animation: ${props => slideYKeyframes(props.yPixelOffset)} 0.5s cubic-bezier(0.61, 1, 0.88, 1) both;
    color: inherit;
    display: inherit;
    position:inherit;
`;

