import * as React from "react";
import styled from "styled-components";
import {SessionService} from "../service/SessionService";
import {MainView, SceneContainer} from "../components/SceneContainer";
import {Answer} from "../model/Answer";
import {SlideY} from "../../common/effects/SlideY";
import {Score} from "../components/score/Score";
import {useParams, useHistory} from "react-router-dom";
import {Button} from "../../common/button/Button";
import Delay from "../../common/effects/Delay2";


interface Props {
    sessionId: string,
    sessionService: SessionService,
    onContinue: () => void,
    onQuit: () => void,
}

interface State {
    answers: Array<Answer>,
}

export class SessionResults extends React.Component<Props, State> {

    constructor(props: Readonly<Props>) {
        super(props);
        this.state = {
            answers: [],
        }
    }

    render() {
        return <SceneContainer>
            <MainView>
                <Title><SlideY>Session Complete!</SlideY></Title>
                <ScoreContainer><Score score={this.calcScore()}/></ScoreContainer>
                <ButtonContainer>
                    <Delay millis={1200}>
                        <SlideY>
                            <ActionButton onClick={() => this.props.onContinue()}>{'Continue'}</ActionButton>
                        </SlideY>
                    </Delay>
                    <Delay millis={1400}>
                        <SlideY>
                            <ActionButton onClick={() => this.props.onQuit()}>{'Quit'}</ActionButton>
                        </SlideY>
                    </Delay>
                </ButtonContainer>
            </MainView>
        </SceneContainer>;
    }

    calcScore() {
        if (this.state.answers.length === 0) {
            return 0;
        }
        let sum = this.state.answers
            .map(a => a.score)
            .reduce((prev, curr) => prev + curr, 0);
        return sum / this.state.answers.length;
    }

    componentDidMount() {
        this.fetchResults();
    }

    fetchResults() {
        this.props.sessionService.getResults({sessionId: this.props.sessionId})
            .then(response => this.handleResults(response.answers));
    }

    handleResults(answers: Array<Answer>) {
        this.setState({answers: answers});
    }
}

export function EndSessionPage(props: Omit<Props, "sessionId" | "onContinue" | "onQuit">) {
    let {sessionId} = useParams();
    let history = useHistory();
    return <SessionResults {...props}
                           sessionId={sessionId}
                           onContinue={handleContinue}
                           onQuit={handleQuit}/>

    function handleContinue() {
        props.sessionService.nextSession(sessionId)
            .then(res => history.replace(`/sessions/${res.sessionId}`))
    }

    function handleQuit() {
        history.replace('/sessions');
    }
}

const Title = styled.div`
    font-size: 18px;
    font-weight: bold;
    grid-row: 1;
    height: 80px;
    text-align: center;
`;

const ScoreContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
`;

const ActionButton = styled(Button)`
  margin-bottom: 10px;
  width: 100%;
`;

const ButtonContainer = styled.div`
  margin-top: 100px;
  width: 100%;
`;
