import * as React from "react";
import {Component} from "react";
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import Loader from "react-loader-spinner";
import {Colors} from "../colors/Colors";
export class LoadingIndicator extends Component {
    render() {
        return(
            <Loader
                type="ThreeDots"
                color={Colors.VeryLightGray}
                height={10}
            />
        );
    }
}