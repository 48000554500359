import React from "react";
import {Props as EditProps, TextEdit, AfterEdit} from "./TextEdit";
import styled from "styled-components";
import {Row} from "../../../common/blocks/Blocks";
import {Colors} from "../../../common/colors/Colors";

export interface Props {
    edits: Array<EditProps>,
    onEditSelected: (target: EditProps) => void,
    selected?: number,
}

export function TextEditList({edits, selected = -1, onEditSelected}: Props) {

    let items = new Array<any>();
    edits.forEach((edit: EditProps) => {
        items.push(<Annotation key={edit.index}
                               onClick={(e: any) => {
                                   e.preventDefault();
                                   onEditSelected(edit);
                               }}
                               selected={selected === edit.index}>
            <TextEdit {...edit}/>
        </Annotation>);
        items.push(<AfterEdit key={edit.index + 1242} {...edit}/>);
    })

    return <>{items}</>;
}



export const Annotation = styled(Row)<{ selected: boolean }>`
  display: inline-block;
  flex-direction: row;
  background-color: ${props => props.selected ? Colors.Red : 'none'};
  transition: background-color 200ms ease-out;
  cursor: pointer;
`;
