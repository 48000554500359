import React, {useState} from "react";
import {Button} from "../../../common/button/Button";
import {UserService} from "../../service/UserService";
import {useHistory} from "react-router-dom";
import {SceneContainer} from "../../components/SceneContainer";
import {SlideY} from "../../../common/effects/SlideY";
import {ButtonRow, Form, Input, PasswordLabel, Title, EmailLabel} from "./Common";

export interface Props {
    userService: UserService,
}

export function RegisterPage(props: Props) {

    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const history = useHistory();

    return (
        <SceneContainer>
            <Form autoComplete={'off'}>
                <SlideY yPixelOffset={-10}>
                    <Title>Register</Title>
                    <EmailLabel>Email</EmailLabel>
                    <Input value={email} onChange={(e) => setEmail(e.target.value)} type={'email'}
                           autoComplete={'username'}/>
                    <PasswordLabel>Password</PasswordLabel>
                    <Input value={password} onChange={(e) => setPassword(e.target.value)} type={'password'}
                           autoComplete={'new-password'}/>
                    <ButtonRow><Button onClick={(e) => handleRegister(e)}>Register</Button></ButtonRow>
                </SlideY>
            </Form>
        </SceneContainer>
    )

    function handleRegister(e: React.MouseEvent<HTMLButtonElement, MouseEvent>) {
        e.preventDefault();
        props.userService.register({email, password})
            .then(value => {
                history.replace("/sessions");
            });
    }

}
