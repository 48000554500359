import * as React from "react";
import {StartSessionPage} from "./StartSessionPage";
import {SubjectService} from "../service/SubjectService";
import {SessionService} from "../service/SessionService";
import {UserService} from "../service/UserService";
import {PlaySessionPage} from "./PlaySessionPage";
import {EndSessionPage} from "./EndSessionPage";
import {BrowserRouter as Router, Route, Redirect, RouteProps, Switch, HashRouter} from "react-router-dom";
import {LoginPage} from "./login/LoginPage";
import {useEffect, useState} from "react";
import {LoadingPage} from "./LoadingPage";
import {RegisterPage} from "./login/RegisterPage";
import {DemoPage} from "./DemoPage";

type Props = {
    subjectService: SubjectService,
    sessionService: SessionService,
    userService: UserService,
}

export function GameManager(props: Props) {

    let {userService, subjectService, sessionService} = props;

    return <HashRouter>
        <Switch>
            <Route path={'/demo'} exact>
                <DemoPage userService={userService}/>
            </Route>
            <Route path={'/login'}>
                <LoginPage userService={userService}/>
            </Route>
            <Route path={'/register'}>
                <RegisterPage userService={userService}/>
            </Route>
            <PrivateRoute exact userService={userService} path={'/sessions'}>
                <StartSessionPage subjectService={subjectService}
                                  sessionService={sessionService}/>
            </PrivateRoute>
            <PrivateRoute exact userService={userService} path={'/sessions/:sessionId'}>
                <PlaySessionPage sessionService={sessionService}/>
            </PrivateRoute>
            <PrivateRoute exact userService={userService} path={'/sessions/:sessionId/results'}>
                <EndSessionPage sessionService={sessionService}/>
            </PrivateRoute>
            <PrivateRoute userService={userService} path={'/'}>
                <Redirect to={{pathname: "/sessions"}}/>
            </PrivateRoute>
        </Switch>
    </HashRouter>;

}

type PrivateRouteProps = {
    userService: UserService,
} & RouteProps;

function PrivateRoute(props: PrivateRouteProps) {
    const {children, ...rest} = {...props};
    const [authenticating, setAuthenticating] = useState(true);
    const [isLoggedIn, setIsLoggedIn] = useState(true);

    useEffect(() => {
        props.userService.isAuthenticated()
            .then(value => {
                setIsLoggedIn(value);
                setAuthenticating(false);
            })
            .catch(reason => {
                setIsLoggedIn(false);
                setAuthenticating(false);
            });
    }, [props.userService]);

    if (authenticating) {
        return <LoadingPage/>;
    }

    return (
        <Route {...rest} render={({location}) =>
            isLoggedIn ? (
                children
            ) : (
                <Redirect to={{
                    pathname: "/login",
                    state: {from: location}
                }}/>
            )
        }/>
    )
}
