import * as React from 'react';
import styled from "styled-components";
import amethyst from './avatar-icons/icons8-amethyst-universe-100.png';
import smurf from './avatar-icons/icons8-smurf-100.png';
import brave from './avatar-icons/icons8-brave-100.png';
import brutus from './avatar-icons/icons8-brutus-100.png';
import cheburashka from './avatar-icons/icons8-cheburashka-100.png';
import garnetUniverse from './avatar-icons/icons8-garnet-universe-100.png';
import {stringify} from "querystring";


export interface AvatarProps {
    id: string,
}

export class Avatar extends React.Component<AvatarProps> {
    render(): React.ReactNode {
        // TODO security risk here
        let {id} = this.props;
        let avatar = Avatars.filter(v => v.id === id)[0];
        if (!avatar) {
            console.log('No such avatar: ' + id);
        }
        return <AvatarIcon src={avatar.path}/>;
    }

    static getRandom(): React.ReactNode {
        let avatar = Avatars[Math.floor(Math.random() * Avatars.length)];
        return <Avatar id={avatar.id}/>;
    }

    static getRandomIds(count = 1): Array<string> {
        const elements: string[] = [];

        function getRandomElement(arr: string[]): Array<string> {
            if (elements.length < count) {
                const index = Math.floor(Math.random() * arr.length)
                const element = arr.splice(index, 1)[0];

                elements.push(element)

                return getRandomElement(arr);
            } else {
                return elements;
            }
        }

        return getRandomElement(Avatars.map(value => value.id));
    }
}


const AvatarIcon = styled.img<{ src: string }>`
    src: url(${props => props.src});
    width: 50px;
`;

const Avatars = [
    {
        id: 'amethyst',
        path: amethyst
    },
    {
        id: 'smurf',
        path: smurf
    },
    {
        id: 'brave',
        path: brave
    },
    {
        id: 'brutus',
        path: brutus
    },
    {
        id: 'cheburashka',
        path: cheburashka
    },
    {
        id: 'garnetUniverse',
        path: garnetUniverse
    },
]