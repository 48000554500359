import axios, {AxiosResponse} from "axios";
import {Subject} from "../model/Subject";

export type GetAllSubjects = {
}

export interface SubjectService {
    getAllSubjects(request: GetAllSubjects): Promise<Array<Subject>>;
}

export class RESTSubjectService implements SubjectService {

    async getAllSubjects(request: GetAllSubjects): Promise<Array<Subject>> {
        let subjects:Array<Subject> = [];
        try {
            let response = await axios.get<GetAllSubjects, AxiosResponse<Array<Subject>>>(
                '/subjects',
            );
            subjects = response.data;
        } catch (e) {
            console.log("error fetching subjects: " + e);
        }
        return subjects;
    }

}