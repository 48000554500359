import * as React from 'react';
import styled from "styled-components";
import {TextArea} from "./TextArea";

export interface Props {
    value?: string,
    clear: boolean,
    onChange?: (answer: string) => void,
    onSubmit: (answer: string) => void,
    focus: boolean,
}

export class TextAnswerInput extends React.Component<Props> {

    ref: TextArea | null = null;
    static defaultProps = {
        focus: true,
        clear: false,
    };

    render() {

        return <Container>
            <TextArea
                ref={c => this.ref = c}
                value={this.props.value}
                onChange={(value: string) => this.props.onChange?.(value)}
                onSubmit={(value: string) => this.props.onSubmit(value)}
            />
        </Container>
    }

}

const Container = styled.div`
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
   
`;