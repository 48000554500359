import * as React from "react";
import styled from "styled-components";
import {Colors} from "../colors/Colors";

export interface ButtonProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {
    value?: string,
    hidden?: boolean,
    selected?: boolean,
    disabled?: boolean,
}

export class Button extends React.Component<ButtonProps> {
    render() {
        return <BasicButtonStyle type={'button'}{...this.props}>{this.props.value}{this.props.children}</BasicButtonStyle>
    }
}

export const BasicButtonStyle = styled.button<ButtonProps>`
    visibility: ${props => !!props.hidden ? "hidden" : "visible"};
    //display: inline-block;
    display: flex;
    justify-content: center;
    align-items: center;
    
    box-sizing: border-box;
    font-family: inherit;
    font-size: 14px;
    font-weight: 600;
    cursor: pointer;
    //padding: 15px;
    border-radius: 6px;
    padding-left: 15px;
    padding-right: 15px;
    height: 35px;
    border: none;
    color: ${Colors.White};
    background-color: ${Colors.Gray};
    text-overflow: clip;
    transition: all 150ms cubic-bezier(0.250, 0.460, 0.450, 0.940);
    
    &:active {
        border-bottom-width: 2px;
        transform: translate3d(0, 2px, 0);
    }
    
    &:focus {
        outline:0;
        background-color: ${Colors.LightGray};
    }
    
    &:hover {
        background-color: ${Colors.LightGray};
    }
`;