import {Text} from "../../../common/text/Text";
import {Colors} from "../../../common/colors/Colors";
import {Bounce} from "../../../common/effects/Bounce";
import SlideUpIn from "../../../common/effects/SlideUpIn";
import {UpArrowIcon} from "../../../common/icons/Icons";
import React from "react";
import {Annotation as AnnotationData} from "../../model/Answer";
import {MarkedError} from "./MarkedError";

export interface Props extends AnnotationData {
    state: 'hidden' | 'marked' | 'corrected',
}

export function AfterEdit({operation, source, target, state}: Props) {
    if (operation === 'insert' && state !== 'hidden') {
        return <Text>{target.after}</Text>;
    }
    return <Text>{source.after}</Text>;
}

export function TextEdit(props: Props) {

    const {state, operation, source, ignored} = {...props};

    if (state === 'hidden') {
        return <HiddenEdit>{source.text}</HiddenEdit>;
    }

    if (operation === 'delete' && ignored) {
        return <IgnoredDelete>{source.text}</IgnoredDelete>;
    }

    if (ignored) {
        return <IgnoredError>{source.text}</IgnoredError>;
    }

    if (operation === 'equal') {
        return <MarkedEqual>{source.text}</MarkedEqual>;
    }

    if (operation === 'insert') {
        return <MarkedInsert/>;
    }

    return <MarkedOther>{source.text}</MarkedOther>;
    
}

function HiddenEdit(props: {children: string}) {
    return <Text color={Colors.ExtraLightGray}>{props.children}</Text>;
}

function MarkedEqual(props: {children: string}) {
    return <Bounce>
        <Text>{props.children}</Text>
    </Bounce>;
}

function MarkedOther(props: {children: string}) {
    return <MarkedError>
        <Text>{props.children}</Text>
    </MarkedError>
}

function MarkedInsert() {
    return <MarkedError>
        <SlideUpIn>
            <UpArrowIcon/>
        </SlideUpIn>
    </MarkedError>
}

function IgnoredError(props: {children: string}) {
    return <Bounce>
        <Text>{props.children}</Text>
    </Bounce>;
}

function IgnoredDelete(props: {children: string}) {
    return <Text color={Colors.ExtraLightGray}>{props.children}</Text>;
}
