import React, {Component} from 'react';
import styled from "styled-components";
import Appear from "../../../common/effects/Appear";
import {SlideY} from "../../../common/effects/SlideY";
import {Colors} from "../../../common/colors/Colors";

export class TextArea extends Component {

    static defaultProps = {
        placeholderText: 'Type your answer',
        value: '',
    };

    constructor(props) {
        super(props);
        this.ref = React.createRef();
        this.state = {
            value: '',
            focused: true,
            disabled: false,
        }
    }

    static getDerivedStateFromProps(props, state) {
        if (props.value && props.value !== state.value) {
            return {
                value: props.value,
            }
        }
        return null;
    }

    render() {
        let placeholder = null;
        if (this.state.value.length === 0) {
            placeholder = <Appear duration={500}>
                <Placeholder onClick={(e) => this.focus()}>{this.props.placeholderText}</Placeholder>
            </Appear>
        }
        return (<Container {...this.props}>
            {placeholder}
            <EditableText
                focused={true}
                rows={4}
                ref={c => this.ref = c}
                onInput={e => this.handleInput(e)}
                onFocus={() => this.handleFocus()}
                onBlur={() => this.handleBlur()}
                onKeyDown={e => this.handleKeyDown(e)}
                onKeyPress={e => this.handleKeyPress(e)}
                contentEditable={!this.state.disabled}
            />

        </Container>)
    }

    componentDidMount() {
        this.focus();
    }

    handleFocus() {
        // do nothing
    }

    handleBlur() {
        // do nothing
    }

    focus() {
        this.ref.focus();
    }

    handleKeyDown = (event) => {
        // capture Tab key
        if (event.keyCode === 9) {
            event.preventDefault();
        } else if (event.keyCode === 229) {
            // filter noise keys
            event.preventDefault();
        }
    };

    handleKeyPress = (event) => {
        // capture Enter key
        if (event.key === 'Enter') {
            event.preventDefault();
            this.setState({disabled: true});
            this.props.onSubmit(this.state.value);
        }
    };

    handleInput = (event) => {
        let value = event.target.innerText;
        const onChange = this.props.onChange || (() => {
        });
        this.setState({value}, onChange(value));
    };
}

const Container = styled.div`
   width: 100%;
   //display: flex;
   
`;

const Placeholder = styled.div`
    z-index: 0;
    position: absolute;
    //left: 0;
    color: ${Colors.VeryLightGray};
`;

const EditableText = styled.div`
    z-index: 5;
    resize: none;
    width: 100%;
    height: auto;
    overflow: visible;
    text-wrap: normal;
    flex-wrap: wrap;
    //height: min-content; 
    font-family: inherit;  
    font-size: inherit;
    font-weight: inherit;
    background-color: transparent;
    caret-color: ${Colors.White};
    color: inherit;
    padding: 0;
    border: none;
    &:focus {
        outline:0;
    }
    
`;