import * as React from 'react';
import './star-ratings.css';
import styled from "styled-components";
import {keyframes} from "styled-components";

export type Props = {
    amount: number,
}

export class StarRatings extends React.Component<Props> {

    static defaultProps = {
        amount: 0,
    }

    render(): React.ReactNode {
        return (
            <div className={'ratings'}>
                <div className="empty-stars"/>
                <FullStars className={'full-stars'} {...this.props} />
            </div>
        )
    }

}

const FullStars = styled.div<Props>`
    position: absolute;
    left: 0;
    top: 0;
    white-space: nowrap;
    overflow: hidden;
    color: #fde16d;
    width: ${props => props.amount}%;
    animation: ${(props) => expandWidth(props.amount)} 1.5s linear;
`;


const expandWidth = (width: number) => keyframes`
    0% {
        width : 0; 
    }
    100% {
        width : ${width}%;
    }
`;