import React, {Component} from 'react';
import styled, {keyframes} from "styled-components";
import Appear from "./Appear";

export type Props = {
    xPixelOffset: number,
}

export default class SlideX extends Component<Props> {

    static defaultProps = {
        xPixelOffset: 10,
    }

    render() {
        return <Appear duration={500}><Styled {...this.props}>{this.props.children}</Styled></Appear>;
    }
}

const slideXKeyframes = (x: number) => keyframes`
    0% {
        transform: translateX(${x}px);
    }
    100% {
        transform: translateX(0);
    }
`;

const Styled = styled.div<Props>`
    animation: ${props => slideXKeyframes(props.xPixelOffset)} 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
    color: inherit;
`;

