import * as React from "react";
import {Component} from "react";
import styled, {keyframes} from "styled-components";

interface Props {
    duration: number,
}

export default class Appear extends Component<Props> {

    static defaultProps = {
        duration: 700,
    }

    render() {
        return (<Appearing {...this.props}>{this.props.children}</Appearing>)
    }
}

const animation = keyframes`
  from {
    opacity: 0;
  }

  to {
    opacity: 100%;
  }
`;

const Appearing = styled.div<Props>`
    animation: ${animation} ${props => props.duration}ms ease-out 1;
    //animation-fill-mode: both;
`;