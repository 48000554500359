import * as React from "react";
import {Component} from "react";
import styled, {keyframes} from "styled-components";

interface Props {
    duration: number,
}

export class Bounce extends Component<Props> {

    static defaultProps = {
        duration: 500,
    }

    render() {
        return (<Appearing {...this.props}>{this.props.children}</Appearing>)
    }
}


const animation = keyframes`
  0%   {     translateY(0); }
        30%  { transform: scale(1) translateY(-10px); }
       
        100% { transform: scale(1)      translateY(0); }
`;

//
// const animation = keyframes`
//   0%   { transform: scale(1,1)      translateY(0); }
//         10%  { transform: scale(1.1,.9)   translateY(0); }
//         30%  { transform: scale(.9,1.1)   translateY(-10px); }
//         50%  { transform: scale(1.05,.95) translateY(0); }
//         57%  { transform: scale(1,1)      translateY(-2px); }
//         64%  { transform: scale(1,1)      translateY(0); }
//         100% { transform: scale(1,1)      translateY(0); }
// `;

const Appearing = styled.div<Props>`
    //animation: ${animation} ${props => props.duration}ms cubic-bezier(0.280, 0.840, 0.420, 1);
    animation: ${animation} ${props => props.duration}ms ease-out;
`;