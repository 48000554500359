import React from 'react';
import {Annotation as AnnotationData, Answer} from "../../model/Answer";
import styled from "styled-components";
import {Props as EditProps} from "../annotate/TextEdit";
import {TextEditList} from "../annotate/TextEditList";


export type Props = {
    alignment: Array<AnnotationData>,
    onEditSelected?: (target: AnnotationData) => void,
    answer: Answer,
}

interface State {
    annotations: Array<EditProps>,
    selected: number,
}

export class AnnotatedAnswer extends React.Component<Props, State> {

    timeout: any[];

    constructor(props: Readonly<Props>) {
        super(props);
        let annotations = props.alignment.map(a => {
            return {state: 'hidden', ...a,} as EditProps;
        });
        this.state = {
            annotations: annotations,
            selected: -1,
        };
        this.timeout = [];
    }

    componentDidMount() {
        this.state.annotations.forEach((value, index) => {
            let renderDelay = 250 * index;
            let tofunc = setTimeout(() => {
                let props = [...this.state.annotations];
                props[index].state = 'marked';
                this.setState({annotations: props});
            }, renderDelay);
            this.timeout.push(tofunc);
        });
    }

    componentWillUnmount() {
        this.timeout.forEach(value => clearTimeout(value));
        this.timeout = [];
    }

    public render() {

        return <Container>
            <TextEditList edits={this.state.annotations}
                          selected={this.state.selected}
                          onEditSelected={edit => {
                              if (edit.operation === 'equal' || edit.ignored) {
                                  return;
                              }
                              this.setState({selected: edit.index});
                              this.props.onEditSelected?.(edit);
                          }}/>
        </Container>;
    }
}

const Container = styled.div`
    position: relative;
    display: flex;
    flex-direction: row;
    align-items: center;
    flex-wrap: wrap;
    white-space: pre;
    overflow-wrap: break-word;
`;

