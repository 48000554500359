import * as React from "react";
import {Component} from "react";
import styled, {keyframes} from "styled-components";

export default class SlideUpIn extends Component {

    render() {
        return (<Animation {...this.props}>{this.props.children}</Animation>)
    }
}

const animation = keyframes`
    0% {
        opacity: 0;
        transform: translateY(100%) scale(0.6) scaleY(0.5);
    }
    50% {
        opacity: 1;
        transform: translateY(-7%) scale(1) scaleY(1.12);
    }
    75% {
        opacity: 1;
        transform: translateY(3%) scale(1) scaleY(1);
    }
    100% {
        opacity: 1;
        transform: translateY(0%) scale(1) scaleY(1);
    }
`;

const Animation = styled.div`
    animation: ${animation} 500ms ease-out 0s 1;
`;