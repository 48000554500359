import React, {useEffect} from "react";
import {useHistory} from "react-router-dom";
import {UserService} from "../service/UserService";
import {LoadingPage} from "./LoadingPage";

export interface Props {
    userService: UserService,
}

export function DemoPage(props: Props) {

    const history = useHistory();

    useEffect(() => {
        (async function login() {
            await props.userService.login({email: 'demo@grammartoys.com', password: 'mydemouser'});
            history.replace("/sessions");
        })();
    });

    return <LoadingPage/>;
}
