import React from "react";
import {Annotation} from "../../model/Answer";
import './CorrectiveFeedback.css';
import styled from "styled-components";
import {Colors} from "../../../common/colors/Colors";
import SlideUpIn from "../../../common/effects/SlideUpIn";
import SlideX from "../../../common/effects/SlideX";

export interface Props {
    annotation: Annotation,
}

export function CorrectiveFeedback({annotation}: Props) {

    const error = annotation.error;
    const message = annotation.feedback.message;

    return (
        <Container key={annotation.index}>
            <Title>{error.category}</Title>
            <Message dangerouslySetInnerHTML={{__html: message}}/>
        </Container>
    );
}

const Container = styled(SlideX)`
  display:flex;
  flex-direction: column;

`;

const Title = styled.div`
  color: ${Colors.VeryLightGray};
  padding-bottom: 15px;
 
`;

const Message = styled.div`
  font-weight: normal;
`;
