import styled from "styled-components";
import {Colors} from "../../common/colors/Colors";

export const SceneContainer = styled.div`
    font-family: 'Open Sans', sans-serif;
    position: absolute;
    box-sizing: border-box;
    height: 100%;
    width: 100%;  
    background-color: ${Colors.DarkGray};
    color: ${Colors.White};

    
    display: grid;
    grid-template-columns: 1fr 4fr 1fr;
    grid-template-rows: 1fr 4fr 1fr;
`;

export const MainView = styled.div`
    grid-column: 2 / 3;
    grid-row: 2 / 3;
    align-self: center;
    justify-self: center;
    
    padding-bottom: 5%;
`;