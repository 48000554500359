import * as React from "react";
import styled from "styled-components";
import {Subject} from "../model/Subject";
import {SubjectService} from "../service/SubjectService";
import {Button} from "../../common/button/Button";
import {SessionService} from "../service/SessionService";
import {MainView, SceneContainer} from "../components/SceneContainer";
import {SlideY} from "../../common/effects/SlideY";
import {useHistory} from "react-router-dom";
import {AboutInfo} from "../components/AboutInfo";


export interface Props {
    subjectService: SubjectService,
    sessionService: SessionService,
    onSessionStart: (sessionId: string) => void
}

export interface State {
    subjects: Array<Subject>,
    selected?: Subject,
    isSelected: boolean,
}

export class StartSession extends React.Component<Props, State> {

    state = {
        subjects: [],
        isSelected: false,
    }

    componentDidMount() {
        this.loadSubjects();
    }

    private loadSubjects() {
        this.props.subjectService.getAllSubjects({})
            .then(value => this.setState({subjects: value}))
            .catch(reason => console.log(`Failed to fetch subjects: ${reason}`));
    }

    render(): React.ReactNode {
        return <SceneContainer>
            <MainView>
                <Container>
                    <Title><SlideY>{'What shall we practice?'}</SlideY></Title>
                    <SubjectContainer>
                        {this.renderSubjects()}
                    </SubjectContainer>
                </Container>
            </MainView>
            <AboutInfo/>
        </SceneContainer>
    }

    renderSubjects() {
        return this.state.subjects.map((value: Subject, index) =>
            <SubjectButton key={index}>
                <SlideY>
                    <Button onClick={() => this.handleSubjectSelected(value)}>{value.name}</Button>
                </SlideY>
            </SubjectButton>)
    }

    handleSubjectSelected(subject: Subject) {
        // this.setState({isSelected: true, selected: subject});
        this.props.sessionService.start({subjectId: subject.id})
            .then(response => this.props.onSessionStart(response.sessionId));
    }
}

export function StartSessionPage(props: Omit<Props, 'onSessionStart'>) {
    let history = useHistory();
    return <StartSession {...props}
                         onSessionStart={sessionId => history.push(`/sessions/${sessionId}`)}
    />
}


const SubjectButton = styled.div`
  display: inline-block;
  margin: 2px;
  box-sizing: border-box;
`;

const SubjectContainer = styled.div`
  justify-content: center;
  width: 500px;
  height: 320px;
  text-align: center;
`;

const Container = styled.div`
  width: 500px;
  height: 400px;
`;

const Title = styled.div`
    font-size: 18px;
    font-weight: bold;
    grid-row: 1;
    height: 80px;
    text-align: center;
`;
