import React, {useState} from "react";
import {SceneContainer} from "../../components/SceneContainer";
import {Button} from "../../../common/button/Button";
import {UserService} from "../../service/UserService";
import {useHistory} from "react-router-dom";
import {SlideY} from "../../../common/effects/SlideY";
import {ButtonRow, Form, Input, PasswordLabel, Title, EmailLabel, LinkButton, SubTitle, DemoLink} from "./Common";

export interface Props {
    userService: UserService,
}

export function LoginPage(props: Props) {

    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const history = useHistory();

    return (
        <SceneContainer>
            <Form>
                <SlideY>
                    <Title>Login</Title>
                    <EmailLabel>Email</EmailLabel>
                    <Input value={email} onChange={(e) => setEmail(e.target.value)} type={'email'}/>
                    <PasswordLabel>Password</PasswordLabel>
                    <Input value={password} onChange={(e) => setPassword(e.target.value)} type={'password'}/>
                    <ButtonRow><Button onClick={(e) => handleLogin(e)}>Login</Button></ButtonRow>
                    <ButtonRow><LinkButton onClick={(e) => handleRegister(e)}>New User? Register Here</LinkButton></ButtonRow>
                </SlideY>
            </Form>
        </SceneContainer>
    )

    function handleLogin(e: React.MouseEvent<HTMLButtonElement, MouseEvent>) {
        e.preventDefault();
        props.userService.login({email, password})
            .then(value => {
                history.replace("/sessions");
            });
    }

    function handleRegister(e: React.MouseEvent<HTMLButtonElement, MouseEvent>) {
        e.preventDefault();
        history.push("/register");
    }

}
