import styled, {keyframes} from "styled-components";
import {Color} from "../colors/Colors";

export interface Props {
    color: Color,
}

const slideKeyframes = () => keyframes`
    0% {
        transform: translateY(10px);
        opacity: 0;
    }
    100% {
        transform: translateY(0px);
        opacity: 100%;
    }
`;

export const Underlined = styled.div<Props>`
    position: relative;
    display: inline-block;
    
    ::after {
        content: ' ';
        position: absolute;
        top: 100%;
        left: 0;
        width: 100%;
        height: 2px;
        opacity: 100%;
        z-index: 0;
        background: ${props => props.color};
        animation: ${slideKeyframes()} 500ms cubic-bezier(0.61, 1, 0.88, 1) both;
    }
`;