import * as React from "react";
import styled from "styled-components";
import {Colors} from "../../../common/colors/Colors";

export type Props = {
    value: number,
}

export class ProgressBar extends React.Component<Props> {

    static defaultProps = {
        value: 0
    };

    render() {
        let normalized = this.props.value > 1 ? 1 : this.props.value;
        return (
            <ProgressBarContainer {...this.props}>
                <ProgressBarValue value={normalized}/>
            </ProgressBarContainer>
        );
    }
}

const ProgressBarContainer = styled.div`
    height: 4px;
    border-radius: 10px;
    background-color: ${Colors.LightGray};
    margin: auto;
`;
const ProgressBarValue = styled.div<{ value: number }>`
    background-color: #FFB627;
    height: 100%;
    width: calc(100% * ${prop => prop.value});
    border-radius: 10px;
    transition: width 1500ms ease-out;
`;