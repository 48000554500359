import * as React from 'react';
import {StarRatings} from "../score/rating/StarRatings";
import styled from "styled-components";
import Delay from "../../../common/effects/Delay";
import Appear from "../../../common/effects/Appear";
import SlideX from "../../../common/effects/SlideX";
import {Score} from "../score/Score";
import {Colors} from "../../../common/colors/Colors";
import Delay3 from "../../../common/effects/Delay3";
import {SlideY} from "../../../common/effects/SlideY";
import Delay2 from "../../../common/effects/Delay2";
import SlideUpIn from "../../../common/effects/SlideUpIn";
import {Annotation} from "../../model/Answer";
import {CorrectiveFeedback} from "./CorrectiveFeedback";

export interface Props {
    score: number, // [0,1]
    mistakes: number,
    annotation?: Annotation,
    showFeedback: boolean,
}

export function Result(props: Props) {

    const normalizedScore = Math.round(props.score * 100);

    return (
        <Container>
            <FeedbackContainer feedback={props.showFeedback}>
                {props.showFeedback ?
                    <Delay2><CorrectiveFeedback annotation={props.annotation!}/></Delay2> : null}
            </FeedbackContainer>
            <ResultContainer>
                <StarScore score={normalizedScore}/>
                <Delay2 millis={350}><PointCount score={normalizedScore}/></Delay2>
                <Delay2 millis={700}><MistakeCount {...props}/></Delay2>
            </ResultContainer>
        </Container>
    )
}

function StarScore(props: { score: number }) {
    return <ResultItemContainer><StarRatings amount={props.score}/></ResultItemContainer>;
}

function PointCount(props: { score: number }) {
    let noun = 'points';
    if (props.score === 1) {
        noun = "point";
    }
    return (
        <ResultItemContainer>{`${props.score} ${noun}`}</ResultItemContainer>
    )
}

function MistakeCount(props: { mistakes: number }) {
    let message;
    if (props.mistakes > 1) {
        message = props.mistakes + ' mistakes';
    } else if (props.mistakes === 1) {
        message = "1 mistake";
    } else {
        message = "0 mistakes";
    }
    return (
        <ResultItemContainer>{message}</ResultItemContainer>
    )
}

const Container = styled.div`
    height: 100px;
    width: 100%;
`;

const FeedbackContainer = styled.div<{ feedback: boolean }>`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  transition: height 250ms ease-out;
  height: ${props => props.feedback ? 80 : 0}px;
`;

const ResultContainer = styled.div`
  height: 100%;
  width: 350px;
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;
`;

const ResultItemContainer = styled(SlideX)`
  width: 115px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  color: ${Colors.VeryLightGray};
  //font-weight: 600;
`;

