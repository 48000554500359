import React, {Component} from 'react';
import styled, {keyframes} from "styled-components";

interface Props {
    millis: number,
}

interface State {
    visible: boolean,
}

export default class Delay3 extends Component<Props, State> {

    static defaultProps = {
        millis: 100
    };

    constructor(props: Readonly<Props>) {
        super(props);
        this.state = {
            visible: props.millis <= 10
        }
    }

    componentDidMount(): void {
        if (this.state.visible) return;
        setTimeout(() => {
            this.setState({visible: true})
        }, this.props.millis);
    }

    render() {
        if (this.state.visible) {
            return this.props.children;
        }
        return <></>;
    }
}

const Styled = styled.div<State>`
    // visibility: ${props => props.visible ? "visible" : "hidden"};
    opacity: ${props => props.visible ? 1 : 0} !important;
`;