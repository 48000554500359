import React from 'react';
import './App.css';
import axios from 'axios';
import {GameManager} from "./play/scene/GameManager";
import {RESTSubjectService} from "./play/service/SubjectService";
import {RESTSessionService} from "./play/service/SessionService";
import {RESTUserService} from "./play/service/UserService";

function App() {

    axios.defaults.baseURL = 'https://www.grammartoys-api.com';
    // axios.defaults.baseURL = 'http://0.0.0.0:80';

    axios.interceptors.request.use(
        config => {
            const token = localStorage.getItem('jwtToken');
            if (token) {
                config.headers['authorization'] = `${token}`;
                config.headers['Content-Type'] = 'application/json';
            }
            return config;
        },
        error => {
            return Promise.reject(error);
        }
    );

    return (
        <GameManager sessionService={new RESTSessionService()}
                     subjectService={new RESTSubjectService()}
                     userService={new RESTUserService()}/>
    );
}

export default App;


