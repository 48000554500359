import React from "react";
import {LoadingIndicator} from "../../common/loader/LoadingIndicator";
import {MainView, SceneContainer} from "../components/SceneContainer";

export function LoadingPage() {
    return <SceneContainer>
        <MainView>
            <LoadingIndicator/>
        </MainView>
    </SceneContainer>;
}


