import * as React from "react";
import {Colors} from "../../common/colors/Colors";
import SlideX from "../../common/effects/SlideX";
import {Link} from "../../common/button/Link";
import {Footer} from "./Footer";
import {Text} from "../../common/text/Text";


export function AboutInfo() {
    return <Footer>
        <SlideX xPixelOffset={-30}>
            <Text color={Colors.LightGray}>
            GrammarToys 2020, AI Powered Exercise Sheets  | <Link target="_blank" href="mailto: guy.manzurola@gmail">get in touch</Link>
            </Text>
        </SlideX>
    </Footer>
}

