import * as React from "react";
import styled from "styled-components";
import goodIcon from "./assets/good.png";
import markIcon from "./assets/noun_Check Mark_1747191.png";
import clapIcon from "./assets/noun_clap_2345412.png";
import likeIcon from "./assets/noun_like_997801.png";
import upArrow from "./assets/noun_chevron_713011.png";

interface IconProps {
    src: string
    size?: number,
}

export const Icon = styled.img<IconProps>`
    src: url(${props => props.src});
    width: ${props => props.size || 40}px;
    background-color: #D9D9D9;
    border-radius: 40px;
    padding: 7px;
`;

export const GoodIcon = styled(Icon).attrs({src: goodIcon})`
`;

export const CheckMark = styled(Icon).attrs({src: markIcon})`
    background-color: white;
    border-radius: 100px;
    // left: 50px;
    // position: absolute;
`;

export const Clap = styled(Icon).attrs({src: clapIcon})`
  background-color: #FFFFFF;
`;

export const LikeIcon = styled(Icon).attrs({src: likeIcon, size: 50})`
  background-color: #FFFFFF;
`;

export const UpArrowIcon = styled(Icon).attrs({src: upArrow})`
  background-color: transparent;
  height: 1em;
  width: 20px;
  padding: 0;
`;