import React from "react";
import {Color, Colors} from "../../../common/colors/Colors";
import {Underlined} from "../../../common/effects/Underlined";
import styled from "styled-components";

export interface Props {
    children: any,
}

export function MarkedError(props: Props) {
    return <Background color={Colors.Red}>
        <Underlined color={Colors.BrightRed}>
            {props.children}
        </Underlined>
    </Background>;
}

const Background = styled.div<{ color: Color }>`
    background-color: ${props => props.color};
    transition: background-color 200ms ease-out;
`;
